@tailwind base;
@layer base {
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: none;
  }
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/Inter-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/Inter-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto Mono';
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/RobotoMono-Medium.woff2') format('woff2');
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

html,
body {
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  font-weight: 500;
  overflow: auto;
}

body {
  line-height: 1.5;
  color: var(--colors-grey400);
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

.scroll-bar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-bar-none::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  /*width: 5px;*/
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#hubspot-messages-iframe-container {
  background-color: transparent !important;
}

.shadow-container {
  visibility: hidden !important;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;
}
.loading > svg {
  box-sizing: border-box;
  display: block;
  position: absolute;
  animation: loading 0.8s linear infinite;
  transform-origin: center;
}

.loading-big {
  width: 40px;
  height: 40px;
}
.loading-big > span {
  width: 28px;
  height: 28px;
  border-width: 4px;
}

.loading.loading-black > span {
  border-color: #000 transparent transparent transparent;
}

.loading span:nth-child(1) {
  animation-delay: -0.45s;
}

.loading span:nth-child(2) {
  animation-delay: -0.3s;
}

.loading span:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
